import img1 from "../photos/carousalphotos/1.jpeg";
import img2 from "../photos/carousalphotos/2.jpeg";
import img3 from "../photos/carousalphotos/3.jpeg";
import img4 from "../photos/carousalphotos/4.jpeg";

let data = [
    {
        id: 1,
        imgSrc: img1,
    },
    {
        id: 3,
        imgSrc: img3,
    },
    {
        id: 2,
        imgSrc: img2,
    },
    {
        id: 4,
        imgSrc: img4,
    },
];

export default data;
